<template>
  <div>
    <CCard>
      <CCardHeader>
        <CIcon name="cil-notes"/> 日付指定
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol>
            <h6>絞り込み</h6>
            <CForm>
              <CRow>
                <CCol md="6">
                  <CInput
                    label="日付"
                    type="date"
                    v-model="form.displayDate"
                    :isValid="checkDisplayDate()"
                    invalidFeedback="選択してください。"/>
                </CCol>
              </CRow>
            </CForm>
          </CCol>
        </CRow>
        <CRow>
          <CCol md="6">
            <CButton type="submit" color="info" class="w-50" @click="displayBatch()">
              表示
            </CButton>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    <CCard>
      <CCardHeader>
        <CIcon name="cil-notes"/> バッチ処理状況
      </CCardHeader>
      <CCardBody>
        <div v-for="(usage, index) in this.usages" v-bind:usage="usage" v-bind:index="index" v-bind:key="usage.transactionID">
          <CRow>
            <CCol md="3">
              {{ usage.storeCode }} {{ usage.storeName }}
            </CCol>
            <CCol md="3">
              {{ usage.count }} 件
            </CCol>
            <CCol md="6">
              <CButton type="submit" color="info" class="w-50" @click="dangerModal = true">
                売上記録を削除
              </CButton>
            </CCol>
          </CRow>
          <CModal
            title="売上記録削除"
            color="danger"
            :show.sync="dangerModal"
            @ok="onClickedDelete()"
          >
            本当に削除しますか？
            <template #footer>
              <CButton @click="dangerModal = false" color="secondary">キャンセル</CButton>
              <CButton @click="onClickedDelete()" color="danger">OK</CButton>
            </template>
          </CModal>
        </div>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import firebase from '@firebase/app';

export default {
  name: "Apibatch",
  data() {
    return {
      show: true,
      form: {
          displayDate: "",
      },
      auth: {},
      coupons: {},
      usages: {},
      users: {},
      accountOrigin: {
        id: '',
        name: '',
        role: 1,
        registered: null,
        postal_code: '',
        phone_number: '',
        prefectures: '',
        address: '',
      },
      account: {},
      stores: [],
      displayCompanyCode: '000000',
      formCollapsed: true,
      dangerModal: false,
    };
  },
  methods: {
    async init() {
      this.account = JSON.parse(JSON.stringify(this.accountOrigin))
      this.stores = []

      let id
      let event
      id = firebase.auth().currentUser.uid
      event = firebase.app().functions('asia-northeast1').httpsCallable('me')
      
      this.$store.commit("showLoading")

      // ユーザーデータ取得
      event({uid: id}).then(function(res) {
        this.isNew = false
        this.auth.uid = res.data.auth.uid
        this.auth.email = res.data.auth.email
        this.account = res.data.user
        if (this.account.companyCode === undefined) {
          this.account.companyCode = 0
        }
        this.displayCompanyCode = ('000000' + this.account.companyCode).slice(-6);

        this.fetchStore()
        this.$store.commit("hideLoading")
      }.bind(this));
    },
    fetchStore() {
      // 店舗情報取得
      firebase.firestore().collection('stores').where('created_user_id', '==', this.account.id).get().then(function(result) {
        let stores = []
        result.forEach(function(doc) {
          const store = doc.data()
          store.displayStoreCode = ('000000' + store.storeCode).slice(-6);
          stores.push(store)
        })
        this.stores = stores
      }.bind(this))
    },
    checkDisplayDate() {
      const form = this.form
      if (form.displayDate != null && form.displayDate.length > 0) {
        return true
      }
      return false
    },
    async displayBatch () {
      const form = this.form
      var usages_where = firebase.firestore().collection("usages");
      if (form.displayDate == null || form.displayDate.length == 0) {
        return
      }
      var date = new Date(form.displayDate)
      date.setHours(0, 0, 0, 0)
      var startUnixTime = Math.floor(date.getTime() / 1000)
      var endUnixTime = startUnixTime + 24 * 60 * 60
      usages_where = usages_where.where("registeredTime", ">=", startUnixTime).where("registeredTime", "<", endUnixTime)
      await usages_where.get().then(
          function(result) {
              let tmp = []
              result.forEach (function(doc) {
                  tmp.push(doc.data())
              })
              var stores = this.stores
              var group = tmp.reduce(function (result, current) {
                var element = result.find(function (p) {
                  return p.storeCode === current.storeCode
                });
                if (element) {
                  element.count ++;
                } else {
                  const targetStore = stores.find((v) => ('000000' + v.storeCode).slice(-6) === current.storeCode);
                  result.push({
                    storeCode: current.storeCode,
                    storeName: targetStore.name,
                    count: 1,
                  });
                }
                return result;
              }, []);
              this.usages = group
          }.bind(this)
      );
    },
    async onClickedDelete() {
      this.$store.commit("showLoading");

      // 売上記録削除
      const form = this.form
      var usages_where = firebase.firestore().collection("usages");
      if (form.displayDate == null || form.displayDate.length == 0) {
        return
      }
      var date = new Date(form.displayDate)
      var startUnixTime = Math.floor(date.getTime() / 1000)
      var endUnixTime = startUnixTime + 24 * 60 * 60
      usages_where = usages_where.where("registeredTime", ">=", startUnixTime).where("registeredTime", "<", endUnixTime)
      var result = await usages_where.get()
      result.forEach (function(doc) {
          doc.ref.delete()
      })
      this.usages = []

      this.$store.commit("hideLoading")
    },
  },
  created() {
    this.init();
  },
  watch: {
    $route: function() {
      this.init();
    }
  }
};
</script>
